<template>
    <div>
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <!--        <div class="filter">-->
        <!--            <div class="butt" @click="ruleVisible = true">创建+</div>-->
        <!--            <el-input prefix-icon="el-icon-search" placeholder="请输入搜索内容" v-model="searchValue" clearable></el-input>-->
        <!--        </div>-->
        <div class="list">
            <div class="item" @click="$router.push({ path: 'informationDetails', query: { id: item.id } })" v-for="(item, index) in consultingList" :key="index">
                <div>
                    <img v-if="item.picUrl" :src="item.picUrl.split(',')[0]" alt="" />
                    <!-- <img v-else src="@/assets/images/Frame-62.png" /> -->
                    <span class="title">{{ item.name }}</span>
                </div>
            </div>
        </div>
        <el-pagination @size-change="getConsultingList" @current-change="getConsultingList" :current-page.sync="page" :page-sizes="[12, 24, 36, 48]" :page-size.sync="limit" layout="prev, pager, next,jumper" class="pagination" :total="total" background></el-pagination>
        <!--创建社群-->
    </div>
</template>

<script>
import uploadFile from '@/utils/oss/ossClient';
import { getConsultingList } from '@/api';

export default {
    name: 'about',
    data() {
        return {
            limit: 12,
            page: 1,
            total: 0,
            consultingList: [],
            nav: [{ title: '首页', to: { path: '/' } }, { title: '资讯动态' }],
        };
    },
    watch: {},
    created() {
        this.getConsultingList();
    },
    methods: {
        getConsultingList() {
            let data = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                libraryId: localStorage.getItem('id'),
            };
            getConsultingList(data).then((res) => {
                console.log('动态咨询', res);
                this.consultingList = res.rows;
            });
        },
    },
};
</script>

<style scoped lang="less">
.filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;

    ::v-deep .el-input {
        width: 240px;
        height: 30px;
        border-radius: 50px;
    }

    ::v-deep .el-input__inner {
        border-radius: 20px;
        height: 30px;
    }

    ::v-deep .el-input__icon {
        line-height: 30px;
    }

    .butt {
        padding: 4px 12px;
        background: #f33f3e;
        border-radius: 2px;
        color: #ffffff;
        cursor: pointer;
    }
}

.list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 30px;

    .item {
        margin-bottom: 56px;
        cursor: pointer;

        div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        img {
            width: 100%;
            height: 175px;
            border-radius: 2px;
            filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16));
        }

        .title {
            font-weight: 700;
            font-size: 18px;
            line-height: 26px;
            text-align: center;
            color: #333333;
            flex: none;
            order: 1;
            flex-grow: 0;
            margin-top: 13px;
            width: 90%;
            .ellipsisLine(1);
        }
    }
}
.dialog {
    padding-top: 8px;
    .item {
        display: flex;
        flex-direction: column;
        ::v-deep .el-input__inner {
            border-radius: 4px;
        }

        div {
            margin-bottom: 12px;
            .title {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #333333;
                flex: none;
                order: 0;
                flex-grow: 0;
            }

            img {
                width: 7px;
                height: 17px;
            }
        }

        .jifen {
            font-size: 14px;
            line-height: 20px;
            text-align: right;
            color: #fd8f30;
        }
    }

    .uploads {
        ::v-deep .el-upload--picture-card {
            margin: 0 10px 10px 0;
            width: 76px;
            height: 76px;
            line-height: 86px;
        }
        ::v-deep .el-upload-list__item {
            width: 76px;
            height: 76px;
        }
        .avatar-uploader .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }
        .avatar-uploader .el-upload:hover {
            border-color: #409eff;
        }
        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 178px;
            height: 178px;
            line-height: 178px;
        }
        .avatar {
            width: 178px;
            height: 178px;
            display: block;
        }
    }
}
.dialog1 {
    text-align: center;
}

::v-deep .el-dialog__body {
    padding: 24px 24px 12px 24px;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
}
</style>
